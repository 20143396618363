import React from 'react';
import './InnovationsComingSoon.css'; // Import the CSS file for styling

const InnovationsComingSoon = () => {
    return (
        <div className="coming-soon-container">
            <h1 className="coming-soon-text">Innovations Coming soon...</h1>
        </div>
    );
};

export default InnovationsComingSoon;
